<template>
    <main v-if="posts" id="js-page-content" class="page-content" role="main">
        <!-- Entete-->
        <div class="subheader hidden-lg-up d-flex">
            <portal to="ariane">
                <h1 class="subheader-title mb-2 text-blue-600 hidden lg:block">
                    <i class="fal fa-tachometer-alt text-blue-600 m-2"></i>
                    {{ $t('menu.dashboard') }}
                </h1>
                <p class="fw-500 m-5 hidden lg:block">
                    {{ $t('auth.hello') }}
                    {{ userImpersonated ? userImpersonated.name : user.name }},
                    {{ $t('auth.messagehappened') }}
                </p>
            </portal>
            <portal-target class="d-flex" name="ariane"></portal-target>
        </div>

        <HomeCardsGallery
            :myposts_new_this_week="myposts_new_this_week"
            :myposts_processing_this_week="myposts_processing_this_week"
            :myposts_done_this_week="myposts_done_this_week"
            :new_this_week="new_this_week"
            :processing_this_week="processing_this_week"
            :done_this_week="done_this_week"
        />

        <!-- Stats priorite tickets ouverts -->
        <div class="w-full flex items-center justify-center mt-8">
            <div class="py-6 sm:py-6 md:py-8 bg-white shadow rounded-lg w-full">
                <div class="px-6">
                    <p
                        class="sm:text-2xl text-xl font-semibold leading-6 text-blue-600"
                    >
                        {{ $t('menu.tickets.openedbypriority') }}
                    </p>
                </div>
                <div
                    class="mt-8 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-5 px-6 xl:px-10 gap-y-8 gap-x-12 2xl:gap-x-8"
                >
                    <HomeOpenedTicketsStat
                        :title="$t('post.page.priority.1')"
                        :total="nbTicketsObservation"
                        :width="(nbTicketsObservation / totalTickets) * 100"
                        to="/posts?priority=1"
                    />
                    <HomeOpenedTicketsStat
                        :title="$t('post.page.priority.2')"
                        :total="nbTicketsMineur"
                        :width="(nbTicketsMineur / totalTickets) * 100"
                        to="/posts?priority=2"
                    />
                    <HomeOpenedTicketsStat
                        :title="$t('post.page.priority.3')"
                        :total="nbTicketsModere"
                        :width="(nbTicketsModere / totalTickets) * 100"
                        to="/posts?priority=3"
                    />
                    <HomeOpenedTicketsStat
                        :title="$t('post.page.priority.4')"
                        :total="nbTicketsMajeur"
                        :width="(nbTicketsMajeur / totalTickets) * 100"
                        to="/posts?priority=4"
                    />
                    <HomeOpenedTicketsStat
                        :title="$t('post.page.priority.0')"
                        :total="nbTicketsSans"
                        :width="(nbTicketsSans / totalTickets) * 100"
                        to="/posts?priority=0"
                    />
                </div>
            </div>
        </div>

        <div v-if="isLoad && !loadingStatus" class="w-full box shadow p-6 mt-5">
            <h2
                id="following-tickets"
                class="sm:text-2xl text-xl font-semibold leading-6 text-blue-600"
            >
                {{ $t('menu.tickets.allmyticketsopen') }}
            </h2>
            <PostsTable :filterMyOpenedTickets="myOpenedTicketsFilter" />
            <router-link to="/posts?responsability=operator">
                <button
                    class="mt-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                >
                    {{ $t('menu.tickets.allmyticketsopen') }}
                </button>
            </router-link>
        </div>

        <div v-if="isLoad && !loadingStatus" class="w-full box shadow p-6 mt-5">
            <h2
                id="following-tickets"
                class="sm:text-2xl text-xl font-semibold leading-6 text-blue-600"
            >
                {{ $t('menu.tickets.allmyticketstracked') }}
            </h2>
            <PostsTable :filterMyTrackedTickets="myTrackedTicketsFilter" />
            <router-link to="/posts?responsability=informed">
                <button
                    class="mt-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                >
                    {{ $t('menu.tickets.allmyticketstracked') }}
                </button>
            </router-link>
        </div>

        <!-- Cartographie -->
        <div class="w-full box shadow p-6 mt-5 moverflow-x-auto">
            <h2
                id="localisation-tickets"
                class="sm:text-2xl text-xl font-semibold leading-6 text-blue-600 mb-2"
            >
                {{ $t('menu.tickets.location') }}
            </h2>
            <ul
                class="flex flex-wrap border-b border-gray-200 dark:border-gray-700"
            >
                <li class="mr-2 cursor-pointer" @click="localisationTab = 0">
                    <div
                        v-if="localisationTab === 0"
                        class="inline-block py-4 px-4 text-sm font-medium text-center text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                    >
                        {{ $t('menu.tickets.mylastopentickets') }}
                    </div>
                    <div
                        v-else
                        class="inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                    >
                        {{ $t('menu.tickets.mylastopentickets') }}
                    </div>
                </li>
                <li class="mr-2 cursor-pointer" @click="localisationTab = 1">
                    <div
                        v-if="localisationTab === 1"
                        class="inline-block py-4 px-4 text-sm font-medium text-center text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                    >
                        {{ $t('menu.tickets.alllastopentickets') }}
                    </div>
                    <div
                        v-else
                        class="inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                    >
                        {{ $t('menu.tickets.alllastopentickets') }}
                    </div>
                </li>
            </ul>
            <MapDashboard v-if="postsWithLocation" :posts="postsWithLocation" />
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import HomeCardsGallery from '@/components/HomeCardsGallery'
import MapDashboard from '@/components/Model/Map/MapDashboard'
import PostsTable from '@/components/Model/Post/PostsTable'
import HomeOpenedTicketsStat from '../../components/HomeOpenedTicketsStat'

export default {
    name: 'Home',
    components: {
        MapDashboard,
        HomeCardsGallery,
        PostsTable,
        HomeOpenedTicketsStat
    },
    data: function () {
        return {
            myposts_new_this_week: 0,
            myposts_processing_this_week: 0,
            myposts_done_this_week: 0,
            new_this_week: 0,
            processing_this_week: 0,
            done_this_week: 0,
            postsWithLocation: [],
            nbTicketsObservation: 0,
            nbTicketsMineur: 0,
            nbTicketsModere: 0,
            nbTicketsMajeur: 0,
            nbTicketsSans: 0,
            isLoad: false,
            loadingStatus: true,
            localisationTab: 0,
            priorityDashboard: [],
            postpriority: []
        }
    },
    created: function () {
        const userId = this.userImpersonated
            ? this.userImpersonated.id
            : this.user.id

        this.getMyNeWPostsDashboard(userId).then((postDashboards) => {
            postDashboards.priority_dashboard.forEach((priority) => {
                switch (priority.priority) {
                    case 0:
                        this.nbTicketsSans = priority.total
                        break
                    case 1:
                        this.nbTicketsObservation = priority.total
                        break
                    case 2:
                        this.nbTicketsMineur = priority.total
                        break
                    case 3:
                        this.nbTicketsModere = priority.total
                        break
                    case 4:
                        this.nbTicketsMajeur = priority.total
                        break
                    default:
                        break
                }
            })
            postDashboards.status_dashboard.forEach((status) => {
                if (status.status.final == false) {
                    this.myposts_processing_this_week += status.total
                    if (status.status.default == true) {
                        this.myposts_new_this_week += status.total
                    }
                } else {
                    this.myposts_done_this_week += status.total
                }
            })
        })
        this.getPostsDashboard().then((postDashboards) => {
            postDashboards.status_dashboard.forEach((status) => {
                if (status.status.final == false) {
                    this.processing_this_week += status.total
                    if (status.status.default == true) {
                        this.new_this_week += status.total
                    }
                } else {
                    this.done_this_week += status.total
                }
            })
        })
        this.config.include = 'answers,medias,form'
        this.config.filters = {
            status_id: '1,2,3',
            with_operator: userId,
            with_location: ''
        }
        this.getPostsLocation(this.config).then((response) => {
            this.isLoad = true
            this.postsWithLocation = response.data.posts.data.filter(
                (post) => post.location
            )
        })
        this.getStatus().then(() => {
            this.loadingStatus = false
        })
    },
    watch: {
        localisationTab: function (tab) {
            const userId = this.userImpersonated
                ? this.userImpersonated.id
                : this.user.id
            if (tab === 0) {
                this.config.filters = {
                    status_id: '1,2,3',
                    with_operator: userId,
                    with_location: ''
                }
                this.getPostsLocation(this.config).then((response) => {
                    this.postsWithLocation = response.data.posts.data.filter(
                        (post) => post.location
                    )
                })
            } else {
                this.config.filters = {
                    status_id: '1,2,3',
                    with_location: ''
                }
                this.getPostsLocation(this.config).then((response) => {
                    this.postsWithLocation = response.data.posts.data.filter(
                        (post) => post.location
                    )
                })
            }
        }
    },
    computed: {
        ...mapState('post', {
            posts: (state) => state.postsDashboard,
            total: (state) => state.total,
            myPosts: (state) => state.myPosts
        }),
        ...mapState('auth', {
            user: (state) => state.user
        }),
        ...mapState('admin', {
            userImpersonated: (state) => state.userImpersonated
        }),
        config: {
            get() {
                return this.$store.state.post.myPostsConfig
            },
            set(value) {
                this.$store.commit('post/setmyPostsConfig', value)
            }
        },
        ...mapGetters('post', ['getPostsWithLocation']),
        myOpenedTicketsFilter() {
            this.userImpersonated ? this.userImpersonated.id : this.user.id
            return {
                status_id: '1,2,3',
                with_operator: userId
            }
        },
        myTrackedTicketsFilter() {
            const userId = this.userImpersonated
                ? this.userImpersonated.id
                : this.user.id
            return {
                with_user_informed: userId
            }
        },
        totalTickets: function () {
            return (
                this.nbTicketsObservation +
                this.nbTicketsMineur +
                this.nbTicketsModere +
                this.nbTicketsMajeur +
                this.nbTicketsSans
            )
        }
    },
    methods: {
        ...mapActions('post', [
            'getMyNeWPostsDashboard',
            'getPostsDashboard',
            'getPostsLocation'
        ]),
        ...mapActions('status', ['getStatus'])
    },
    mounted() {}
}
</script>

<style>
.vue-portal-target {
    display: flex;
    align-items: center;
}
</style>
