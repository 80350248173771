<template>
  <div class="flex w-full flex-wrap gap-4">
    <StatsTicketsWeek :heading="myTicketsHeading" :options="myTickets" />
    <StatsTicketsWeek :heading="ticketsHeading" :options="tickets" />
  </div>
</template>

<script>
import StatsTicketsWeek from '@/components/UI/Helper/StatsTicketsWeek.vue'
export default {
  name: 'HomeCardsGallery',
  props: [
    'myposts_new_this_week',
    'myposts_processing_this_week',
    'myposts_done_this_week',
    'new_this_week',
    'processing_this_week',
    'done_this_week',
  ],
  components: {
    StatsTicketsWeek,
  },
  created() {},
  data() {
    return {
      myTicketsHeading: {
        title: this.$t('post.stats.myTicketsTitle'),
        subtitle: this.$t('post.stats.subtitle'),
        to: '/posts?status=mylastposts',
      },
      ticketsHeading: {
        title: this.$t('post.stats.ticketsTitle'),
        subtitle: this.$t('post.stats.subtitle'),
        to: '/posts?status=lastposts',
      },
    }
  },
  computed: {
    myTickets() {
      const totalMyPosts =
        this.myposts_new_this_week +
        this.myposts_processing_this_week +
        this.myposts_done_this_week
      return [
        {
          to: '/posts?status=mynewposts',
          label: this.$t('post.stats.new'),
          value: this.myposts_new_this_week,
          progress: `${(this.myposts_new_this_week / totalMyPosts)*100}%`,
          progressColor: 'yellow',
        },
        {
          to: '/posts?status=myopenedposts',
          label: this.$t('post.stats.open'),
          value: this.myposts_processing_this_week,
          progress: `${(this.myposts_processing_this_week / totalMyPosts)*100}%`,
          progressColor: 'blue',
        },
        {
          to: '/posts?status=mydoneposts',
          label: this.$t('post.stats.done'),
          value: this.myposts_done_this_week,
          progress: `${(this.myposts_done_this_week / totalMyPosts)*100}%`,
          progressColor: 'green',
        },
      ]
    },
    tickets() {
      const totalPosts =
        this.new_this_week + this.processing_this_week + this.done_this_week
      return [
        {
          to: '/posts?status=newposts',
          label: this.$t('post.stats.new'),
          value: this.new_this_week,
          progress: `${((this.new_this_week) / totalPosts) * 100}%`,
          progressColor: 'yellow',
        },
        {
          to: '/posts?status=openedposts',
          label: this.$t('post.stats.open'),
          value: this.processing_this_week,
          progress: `${((this.processing_this_week) / totalPosts) * 100}%`,
          progressColor: 'blue',
        },
        {
          to: '/posts?status=doneposts',
          label: this.$t('post.stats.done'),
          value: this.done_this_week,
          progress: `${((this.done_this_week) / totalPosts) * 100}%`,
          progressColor: 'green',
        },
      ]
    },
  },
}
</script>

<style></style>
