<template>
  <div
    class="min-w-1/3 flex-grow bg-white dark:bg-gray-800 shadow rounded px-8 py-6"
  >
    <router-link :to="heading.to">
      <div class="flex items-center justify-between link">
        <div >
          <p class="sm:text-2xl text-xl font-semibold leading-6 text-blue-600">
            {{ heading.title }}
          </p>

          <p class="text-xs leading-3 text-gray-500 mt-1">
            {{ heading.subtitle }}
          </p>
        </div>
        <div class="pl-4">
        <i class="fas fa-bell" style="color:#0E7490; font-size:2em;"></i>
        </div>
      </div>
    </router-link>
    <div class="mt-6" v-for="option in options" :key="`stats-${option.to}`">
      <router-link :to="option.to">
        <div class="link">
          <div class="flex items-center justify-between">
            <p class="text-sm leading-3 text-gray-500 dark:text-gray-400">
              {{ option.label }}
            </p>
            <p
              class="text-base leading-3 text-right text-gray-800 dark:text-gray-100"
            >
              {{ option.value }}
            </p>
          </div>
          <div class="w-full h-1 bg-gray-200 rounded-full mt-4">
            <div
              :class="`h-1 bg-${option.progressColor}-500 rounded-full`"
              v-bind:style="{ width: option.progress }"
            ></div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsTicketsWeek',
  props: ['heading', 'options'],
  data: function () {
    return {}
  },
  created() {},
}
</script>
<style>
.item:hover {
  background: rgba(0, 0, 0, 0.04);
}
</style>
