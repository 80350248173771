<template>
    <l-map
        v-if="bounds.length > 0"
        :center="center"
        ref="myMap"
        :zoom="zoom"
        :bounds="bounds"
        style="height: calc(100vh - 330px); width: 100%; z-index: 1"
        class="sm:rounded-lg"
        @update:zoom="updateZoom($event)"
    >
        <l-tile-layer :attribution="attribution" :url="url" />
        <v-marker-cluster>
            <l-marker
                v-for="post in posts"
                :key="post.id"
                :lat-lng="getCoord(post)"
                :options="optionsZoom"
            >
                <l-popup>
                    <PanelKanban
                        :post="post"
                        class="w-72 h-30"
                        :withStatus="true"
                    >
                    </PanelKanban>
                </l-popup>
            </l-marker>
        </v-marker-cluster>
    </l-map>
</template>

<script>
import PanelKanban from '../../../components/UI/PanelKanban'

require('leaflet.snogylop')
import { L, latLng, LatLngBounds } from 'leaflet'
import {
    LMap,
    LTileLayer,
    LGeoJson,
    LPolygon,
    LMarker,
    LPopup,
    LIcon,
    LControl,
    LTooltip
} from 'vue2-leaflet'

export default {
    name: 'MapDashboard',
    props: ['posts'],
    components: {
        L,
        LMap,
        LTileLayer,
        LPolygon,
        LMarker,
        LGeoJson,
        LPopup,
        LTooltip,
        LIcon,
        LControl,
        PanelKanban
    },
    data() {
        return {
            url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`,
            attribution: '&copy; OpenStreetMap, &copy; Mapbox',
            imagePath: '',
            icon: '',
            showPOIBox: false,
            optionsZoom: {
                maxZoom: 22,
                maxNativeZoom: 20
            },
            markers: []
        }
    },
    computed: {
        zoom: {
            get() {
                return this.$store.state.map.config.zoom
            },
            set(value) {
                this.$store.commit('map/setZoom', value)
            }
        },
        center() {
            let center = [0, 0]
            let sum = 0
            if (this.posts && typeof this.posts != 'undefined') {
                this.posts.forEach((post) => {
                    center[0] += post.location.lat
                    center[1] += post.location.lon
                    sum += 1
                })
                if (sum == 0) {
                    center[0] = center[0]
                    center[1] = center[1]
                }
                if (sum > 0) {
                    center[0] = center[0] / sum
                    center[1] = center[1] / sum
                }
            }
            return center
        },
        bounds() {
            if (this.posts && typeof this.posts != 'undefined') {
                this.posts.forEach((post) => {
                    this.markers.push({
                        lat: post.location.lat,
                        lon: post.location.lon
                    })
                })
                return this.markers.map((m) => {
                    return [m.lat, m.lon]
                })
            }
            return [[0, 0]]
        }
    },
    methods: {
        updateZoom: function (zoom) {
            this.zoom = zoom
        },
        getCoord(post) {
            return latLng(post.location.lat, post.location.lon)
        }
    },
    created() {}
}
</script>
