<template>
  <router-link :to="to">
    <div class="w-full link">
      <p
        tabindex="0"
        class="focus:outline-none text-xs md:text-sm font-medium leading-none text-gray-500 uppercase"
      >
        {{ title }}
      </p>
      <p
        tabindex="0"
        class="focus:outline-none sm:text-2xl text-xl font-bold leading-3 text-gray-800 mt-3 md:mt-5"
      >
        {{ total }} {{total > 1 ? $t('post.stats.opentickets') : $t('post.stats.openticket') }}
      </p>
      <div class="flex flex-col md:w-64">
        <div class="mt-2.5">
          <div class="w-full h-1 bg-gray-200 rounded-full">
            <div
              class="h-1 bg-green-500 rounded-full"
              v-bind:style="{
                width: width + '%',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'HomeOpenedTicketsStat',
  props: ['title', 'total', 'width', 'to'],
}
</script>

<style></style>
